<!-- src/components/FooterButtons.vue -->
<template>
    <div class="footer-buttons">
      <!-- กลุ่มปุ่มที่ 1 (ปุ่มที่ 1 อยู่ซ้าย) -->
      <div class="button-group left-group">
        <button class="left-button">
            <router-link to="/nft" class="left-button">
          <img :src="button1" alt="Button 1" />
        </router-link>
        </button>
      </div>
      <!-- กลุ่มปุ่มที่ 2-4 (ปุ่มที่ 2-4 อยู่ขวา) -->
      <div class="button-group right-group">
        <button class="right-button">
            <router-link to="/home" class="left-button">
          <img :src="button2" alt="Button 2" />
        </router-link>

        </button>
        <button class="right-button">
            <router-link to="/shop" class="left-button">
          <img :src="button2" alt="Button 2" />
        </router-link>

        </button>
        <button class="right-button">
            <router-link to="/game" class="left-button">
          <img :src="button3" alt="Button 3" />
          </router-link>

        </button>
        <button class="right-button">
            <router-link to="/deposit" class="left-button">
          <img :src="button4" alt="Button 4" />
        </router-link>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import button1 from '@/assets/buttons/button1.png';
  import button2 from '@/assets/buttons/button2.png';
  import button3 from '@/assets/buttons/button3.png';
  import button4 from '@/assets/buttons/button4.png';
  
  export default {
    name: "FooterButtons",
    data() {
      return {
        button1,
        button2,
        button3,
        button4,
      };
    },
  };
  </script>
  
  <style scoped>
  .footer-buttons {
    position: fixed;
    bottom: 5px; /* ระยะจากด้านล่าง */
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between; /* จัดกลุ่มปุ่มซ้ายและขวา */
    align-items: center;
    padding: 0 5px; 
    border-radius: 10px;
    z-index: 1000;
  }
  
  .button-group {
    display: flex;
    align-items: center; 
  }
  
  .right-group {
    display: flex;
    gap: 0px; /* ระยะห่างระหว่างปุ่มขวา */
  }
  
  .left-button img,
  .right-button img {
    width: 60px; /* ขนาดปุ่ม */
    height: 60px;
  }
  
  button {
    background: none;
    border: none;
    padding: 0;
  }
  
  button img {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  button img:hover {
    transform: scale(1.1);
  }
  </style>
  