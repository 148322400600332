<template>
    <div class="top-right-container" >
      <img 
        class="top-right-button" 
        src="/assets/moneybar.png" 
        alt="Money Bar" 
      />
      <span class="coin-text">999999</span>
    </div>
  </template>
  
  <script>
  export default {
    name: "TopRightButton",
    methods: {
      handleClick() {
        this.$emit("right-clicked");
      },
    },
  };
  </script>
  
  <style scoped>
  .top-right-container {
    position: fixed;
    top: 5px;
    right: 0px;
    cursor: pointer;
    z-index: 1000; /* Ensures the button is on top of other content */
  }
  
  .top-right-button {
    width: 150px; /* Adjust the size of the image */
    height: auto;
    transition: transform 0.3s;
  }
  
  .top-right-container:hover .top-right-button {
    transform: translateY(-2px); /* Adds a slight movement when hovered */
  }
  
  .coin-text {
    position: absolute;
    top: 45%; /* Adjust the position of the text */
    left: 55%;
    transform: translate(-50%, -50%);
    color: #363232; /* White text color */
    font-size: 20px; /* Adjust the text size */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add shadow for better readability */
  }
  </style>
  