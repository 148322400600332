// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import TonConnect from '@/components/TonConnect.vue';
import GamePage from '@/components/GamePage.vue'; 
import HomePage from '@/components/HomePage.vue'; 
import NftPage from '@/components/NftPage.vue'; 
import ShopPage from '@/components/ShopPage.vue'; 
import AccountPage from '@/components/AccountPage.vue'; 
import DepositPage from '@/components/DepositPage.vue';  


const routes = [
  {
    path: '/',
    name: 'TonConnect',
    component: TonConnect,
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  {
    path: '/nft',
    name: 'NftPage',
    component: NftPage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  {
    path: '/shop',
    name: 'ShopPage',
    component: ShopPage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  {
    path: '/account',
    name: 'AccountPage',
    component: AccountPage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  {
    path: '/deposit',
    name: 'DepositPage',
    component: DepositPage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  {
    path: '/game',
    name: 'GamePage',
    component: GamePage,
    meta: { requiresAuth: true }, // ระบุว่าหน้านี้ต้องการการล็อกอิน
  },
  // คุณสามารถเพิ่ม routes อื่นๆ ได้ที่นี่
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// เพิ่ม Global Navigation Guard เพื่อตรวจสอบการล็อกอิน
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token'); // ตรวจสอบ token จาก localStorage

  if (requiresAuth && !token) {
    // ถ้าหน้าต้องการการล็อกอินแต่ผู้ใช้ยังไม่ได้ล็อกอิน ให้ไปที่หน้า TonConnect
    return next('/');
  }

  next(); // อนุญาตให้ไปต่อถ้าไม่มีเงื่อนไขพิเศษ
});

export default router;
