<!-- src/components/ProductContainer.vue -->
<template>
    <div class="page-container">
      <div class="product-container" :style="productContainerStyle">
        <img :src="productImage" alt="Product Image" class="product-image" />
        <div class="product-details">
          <h2 class="product-name">{{ productName }}</h2>
          <p class="product-description">{{ productDescription }}</p> 
          <input type="text" class="product-input" placeholder="Enter quantity" />
          <button @click="buyProduct" class="buy-button">Buy Now</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import storeboxbg from '@/assets/storeboxbg.png'; // นำเข้าจาก src/assets
  import rareImage from '@/assets/coinchest.png'; // นำเข้าจาก src/assets
  
  export default {
    name: "ProductContainer",
    data() {
      return {
        productImage: rareImage, // ใช้รูปภาพนำเข้า
        productName: "Buy Box", // ชื่อสินค้า
        productDescription: "1 Box = 1 TON", // รายละเอียดสินค้า 
 
      };
    },
    methods: {
      buyProduct() {
        alert("คุณได้ทำการสั่งซื้อสินค้าเรียบร้อยแล้ว!");
      },
    },
    computed: {
      productContainerStyle() {
        return {
          backgroundImage: `url(${this.storeboxbg})`,
          backgroundSize: 'cover', /* ปรับให้พื้นหลังครอบคลุมเนื้อหา */
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '20px', /* เพิ่ม padding เพื่อให้เนื้อหาไม่ติดขอบ */
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding-top: 50%; /* ระยะห่างจากด้านบน */ 
  }
  
  .product-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 5px;
    padding: 20px;
    /* ลบ max-width เพื่อให้พื้นหลังพอดีกับเนื้อหา */
    max-width: 400px; /* กำหนดขนาดสูงสุดเพื่อควบคุมความกว้าง */
    width: 100%;
    box-shadow: 0px 9px 0px -1px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 9px 0px -1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 9px 0px -1px rgba(0,0,0,0.75);
    background-color: #f0f0f0; /* สีพื้นหลังของหน้า */
  }
  
  .product-image {
    width: 80%; /* ปรับขนาดรูปภาพให้พอดีกับกล่อง */
    height: auto; 
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .product-details {
    text-align: center;
    width: 100%;
  }
  
  .product-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .product-description {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #666;
  }
  
  .product-input {
    width: 100%;
    max-width: 250px;
    padding: 10px 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .product-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  
  .buy-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .buy-button:hover {
    background-color: #0056b3;
  }
  
  /* ปรับแต่งให้ดูดีบนหน้าจอขนาดต่างๆ */
  @media (max-width: 1024px) {
    .product-container {
      max-width: 350px;
    }
  
    .product-input {
      max-width: 200px;
    }
  
    .product-name {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 600px) {
    .product-container {
      max-width: 300px;
    }
  
    .product-input {
      max-width: 100%;
    }
  
    .product-name {
      font-size: 1.2rem;
    }
  
    .product-description {
      font-size: 0.9rem;
    }
  
    .buy-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  </style>
  