<template>
    <div class="top-left-container">
        <router-link to="/account" class="left-button">
      <img 
        class="top-left-button" 
        src="/assets/guestbtn.png" 
        alt="Money Bar" 
      /> 
      </router-link>

    </div>
  </template>
  
  <script>
  export default {
    name: "TopleftButton",
    methods: {
      handleClick() {
        this.$emit("left-clicked");
      },
    },
  };
  </script>
  
  <style scoped>
  .top-left-container {
    position: fixed;
    top: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 1000; /* Ensures the button is on top of other content */
  }
  
  .top-left-button {
    width: 60px; /* Adjust the size of the image */
    height: auto;
    transition: transform 0.3s;
  }
  
  .top-left-container:hover .top-left-button {
    transform: translateY(-2px); /* Adds a slight movement when hovered */
  }
  
  .coin-text {
    position: absolute;
    top: 45%; /* Adjust the position of the text */
    left: 55%;
    transform: translate(-50%, -50%);
    color: #363232; /* White text color */
    font-size: 20px; /* Adjust the text size */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add shadow for better readability */
  }
  </style>
  