<template>
  <div class="container"> 
    <!-- โลโก้ -->
    <img src="@/assets/logo3.png" alt="Logo" class="logo" />

    <!-- ปุ่มเชื่อมต่อกระเป๋าเงิน -->
    <button 
      v-if="!address && !loading"
      id="ton-connect-button" 
      @click="connectWallet"
      class="button primary-button"
      aria-label="Connect your wallet"
    >
      Connect Wallet
    </button>
  
    <!-- แสดงข้อความโหลด -->
    <p v-if="loading" class="loading-message">Processing...</p>

    <!-- แสดงข้อมูลกระเป๋าเงินเมื่อเชื่อมต่อแล้ว -->
    <div v-if="address" class="wallet-info">
      <p class="text">Connected to: {{ formattedAddress }}</p> 
      <p class="text">Balance: {{ balance }} TON</p>
      
      <!-- ปุ่ม Logout -->
      <button 
        @click="logout"
        class="button secondary-button"
        aria-label="Logout"
      >
        Logout
      </button>

      <!-- แสดงประวัติธุรกรรม -->
      <div v-if="transactions.length" class="transactions">
        <h3>Transaction History</h3>
        <ul>
          <li v-for="tx in transactions" :key="tx.id">
            <p>Transaction ID: {{ tx.id }}</p>
            <p>Amount: {{ tx.amount }} TON</p>
            <p>Status: {{ tx.status }}</p>
            <p>Date: {{ formatDate(tx.date) }}</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- แสดงข้อความสำเร็จ -->
    <p v-if="success" class="success-message">{{ success }}</p>

    <!-- แสดงข้อความผิดพลาด -->
    <p v-if="error" class="error-message">{{ error }}</p>
   
  </div>
</template>

<script>
import axios from 'axios';
import { TonConnectUI } from '@tonconnect/ui'; // ตรวจสอบว่า TonConnectUI นำเข้าอย่างถูกต้อง

export default {
  name: 'TonConnect',
  
  data() {
    return {
      tonConnectUI: null,
      address: null,
      error: null,
      success: null,
      loading: false,
      balance: null, // ยอดเงินคงเหลือ
      transactions: [], // ประวัติธุรกรรม
    };
  },
  
  mounted() {
    this.initializeTonConnect();
  },
  
  methods: {
    /**
     * Initialize TonConnectUI and set up status change listener
     */
     initializeTonConnect() {
      try {
        this.tonConnectUI = new TonConnectUI({
          manifestUrl: 'https://minigame.daily-shop.xyz/manifest.json',
        });

        // Listen for wallet status changes
        this.tonConnectUI.onStatusChange(this.handleStatusChange); 
        
      } catch (err) {
        this.setError(`Failed to load TonConnectUI: ${err.message}`);
        console.error('TonConnectUI Initialization Error:', err);
      }
    },
    
    /**
     * Handle wallet status changes
     * @param {Object|null} wallet - The wallet object or null if disconnected
     */
     async handleStatusChange(wallet) {
      if (wallet) {  
        this.address = wallet.account.address;
        this.fetchBalance(this.address); // ดึงยอดเงินเมื่อเชื่อมต่อ 
        this.autoRegister(this.address);

        const currentWallet = this.tonConnectUI.wallet;
        const currentWalletInfo = this.tonConnectUI.walletInfo;
        const currentAccount = this.tonConnectUI.account;
        const currentIsConnectedStatus = this.tonConnectUI.connected;

        console.log(currentAccount);

      } else {
        this.address = null;
        this.balance = null;
        this.transactions = [];
      }
    },
    
    /**
     * Connect to the user's wallet
     */
    async connectWallet() {
      if (this.address) {
        this.setError('Wallet is already connected.');
        return;
      }
      if (!this.tonConnectUI) {
        this.setError('TonConnectUI is not initialized!');
        return;
      }

      this.loading = true; // เริ่มโหลด
      try {
        await this.tonConnectUI.connectWallet();
      } catch (err) {
        // ตรวจสอบว่ามีเมธอด disconnect หรือไม่
        if (err.name === 'WalletAlreadyConnectedError') {
          this.setError('Wallet is already connected.');
        } else {
          this.setError(`Error connecting wallet: ${err.message}`);
        }
        console.error('Connect Wallet Error:', err);
      } finally {
        this.loading = false; // หยุดโหลด
      }
    },
    
    /**
     * Automatically register the user after connecting the wallet
     * @param {string} address - The user's wallet address
     */
    async autoRegister(address) {
      this.loading = true;
      try {
        const response = await axios.post('https://tadmin.daily-shop.xyz/api/autoregister', {
          address,
        });

        const { token, message } = response.data;

        // Store the token securely
        localStorage.setItem('token', token);
        localStorage.setItem('walletAddress', address);

        this.setSuccess(message);

        // Navigate to the game page after successful registration
        // this.navigateToGame();
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        this.setError(`Auto-register failed: ${errorMessage}`);
        console.error('Auto-register Error:', error);
      } finally {
        this.loading = false;
      }
    },
     
    /**
     * Fetch the balance of the connected wallet
     * @param {string} address - The user's wallet address
     */
    async fetchBalance(address) {
      try {
        // แทนที่ URL นี้ด้วย API จริงที่ใช้ดึงยอดเงินจากเครือข่าย Ton
        const response = await axios.get(`https://tonapi.io/v2/accounts/${address}`);
        this.balance = response.data.balance / 1e9;
      } catch (error) {
        this.setError(`Failed to fetch balance: ${error.message}`);
        console.error('Fetch Balance Error:', error);
      }
    }, 
    
    /**
     * Format the date to a readable format
     * @param {string} dateStr - The date string to format
     * @returns {string} - Formatted date
     */
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString();
    },
     
    /**
     * Set the error message
     * @param {string} message - The error message to display
     */
    setError(message) {
      this.error = message;
      this.success = null;
    },
    
    /**
     * Set the success message
     * @param {string} message - The success message to display
     */
    setSuccess(message) {
      this.success = message;
      this.error = null;
    },

    /**
     * Disconnect the wallet
     */
    async disconnectWallet() {
      if (this.tonConnectUI && this.address) {
        // ตรวจสอบว่ามีเมธอด disconnect หรือไม่
        if (typeof this.tonConnectUI.disconnect === 'function') {
          try {
            await this.tonConnectUI.disconnect();
            // รีเซ็ตสถานะหลังจากตัดการเชื่อมต่อ
            this.address = null;
            this.balance = null;
            this.transactions = [];
            this.setSuccess('Wallet disconnected successfully.');
          } catch (err) {
            this.setError(`Error disconnecting wallet: ${err.message}`);
            console.error('Disconnect Wallet Error:', err);
          }
        } else {
          // หากไม่มีเมธอด disconnect ให้รีเซ็ตสถานะด้วยตัวเอง
          console.warn('TonConnectUI does not have a disconnect method.');
          this.address = null;
          this.balance = null;
          this.transactions = [];
          this.setSuccess('Wallet disconnected successfully.');
        }
      }
    },

    /**
     * Logout ผู้ใช้โดยการลบ token และนำทางกลับไปหน้า TonConnect
     */
    async logout() {
      this.loading = true;
      try {
        await this.disconnectWallet(); // ตัดการเชื่อมต่อกระเป๋าเงิน
        localStorage.removeItem('token'); // ลบ token ออกจาก localStorage
        this.setSuccess('Successfully logged out.');
        this.$router.push('/'); // นำทางกลับไปหน้าแรก
      } catch (error) {
        this.setError(`Logout failed: ${error.message}`);
        console.error('Logout Error:', error);
      } finally {
        this.loading = false;
      }
    },
  },
  
  computed: {
    /**
     * Format the address for better readability (e.g., shorten it)
     */
    formattedAddress() {
      if (!this.address) return '';
      return `${this.address.slice(0, 6)}...${this.address.slice(-4)}`;
    },
  },
};
</script>

<style scoped>
:root {
  /* Theme Colors */
  --primary-color: #4CAF50;            /* เขียว */
  --primary-color-dark: #45a049;       /* เขียวเข้ม */
  --secondary-color: #ffffff;          /* ขาว */
  --overlay-color: rgba(0, 0, 0, 0.5); /* สีดำโปร่งแสง */
  
  /* Additional Colors */
  --success-color: #28a745;
  --error-color: #dc3545;
  --text-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column; /* จัดเรียงรายการแนวตั้ง */
  align-items: center;    /* จัดศูนย์รายการแนวนอน */
  justify-content: center;/* จัดศูนย์รายการแนวตั้ง */
  height: 100vh;          /* ความสูงเต็มหน้าจอ */
  text-align: center;     /* จัดตำแหน่งข้อความให้เป็นศูนย์ */

  /* Background Image with Overlay */
  background-image:  
    url('@/assets/bg.png'); /* ตรวจสอบให้แน่ใจว่าพาธถูกต้อง */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


/* สไตล์สำหรับโลโก้ */
.logo {
  width: 90%;           /* ปรับขนาดตามต้องการ */
  margin-bottom: 20px;  /* ช่องว่างด้านล่างโลโก้ */
}

/* สไตล์ปุ่มทั่วไป */
.button {
  padding: 12px 24px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color); /* ใช้สีธีม */
  color: var(--secondary-color);          /* ใช้สีข้อความธีม */
  transition: 
    background-color 0.3s ease, 
    transform 0.2s ease,
    box-shadow 0.3s ease;

  /* เพิ่ม box-shadow สำหรับความลึก */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: var(--primary-color-dark); /* เปลี่ยนสีเมื่อ hover */
  transform: translateY(-2px); /* ยกเลิกตำแหน่งเมื่อ hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.button:active {
  transform: translateY(0); /* รีเซ็ตตำแหน่งเมื่อคลิก */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* สไตล์เฉพาะสำหรับปุ่มหลัก (Connect Wallet) */
.primary-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.primary-button:hover {
  background-color: var(--primary-color-dark);
}

/* สไตล์เฉพาะสำหรับปุ่มรอง (Logout) */
.secondary-button {
  background-color: #f44336; /* แดง */
  color: var(--secondary-color);
}

.secondary-button:hover {
  background-color: #d32f2f; /* แดงเข้ม */
}

/* สไตล์ข้อความ */
.text {
  font-size: 18px;
  margin-top: 10px;
  color: var(--secondary-color); /* ให้แน่ใจว่าข้อความมองเห็นได้ */
}

/* สไตล์ข้อความสำเร็จ */
.success-message {
  color: var(--success-color);
  margin-top: 10px;
}

/* สไตล์ข้อความผิดพลาด */
.error-message {
  color: var(--error-color);
  margin-top: 10px;
}

/* สไตล์ข้อความโหลด */
.loading-message {
  color: var(--secondary-color);
  margin-top: 10px;
  font-style: italic;
}

/* สไตล์สำหรับข้อมูลกระเป๋าเงิน */
.wallet-info {
  margin-top: 20px;
}

.wallet-info .text {
  font-size: 16px;
  margin: 5px 0;
}

/* สไตล์สำหรับประวัติธุรกรรม */
.transactions {
  margin-top: 20px;
  width: 80%;
  max-width: 600px;
}

.transactions h3 {
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.transactions ul {
  list-style-type: none;
  padding: 0;
}

.transactions li {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
</style>
