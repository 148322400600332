<template>
  <div class="account-section">
    <h1 class="section-title">My Account</h1>

    <!-- ส่วนที่ 1: รูปภาพ Avatar และ เลขกระเป๋า -->
    <div class="account-info">
      <img class="avatar" src="/assets/avatar.png" alt="User Avatar" />
      <div class="wallet-info">
        <h2>Wallet Number</h2>
        <p>{{ walletNumber }}</p>
      </div>
    </div>

    <!-- ส่วนที่ 2: ลิงค์อ้างอิงค์ หรือ Ref -->
    <div class="referral-section">
      <h2>Referral Link</h2>
      <input
        type="text"
        class="referral-link"
        :value="referralLink"
        readonly
      />
      <button @click="copyReferralLink">Copy Referral Link</button>
    </div>

    <!-- ส่วนที่ 3: รายงานคนที่ต่อเรพเราและยอดเงินที่เติมมา -->
    <div class="referral-report">
      <h2>Referral Report</h2>
      <table>
        <thead>
          <tr>
            <th>Referred User</th>
            <th>Amount Deposited</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in referralReports" :key="index">
            <td>{{ report.user }}</td>
            <td>{{ report.amount }} COIN</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      walletNumber: "0x1234...abcd", // ตัวอย่างเลขกระเป๋า
      referralLink: "https://example.com/ref/yourcode", // ลิงค์อ้างอิงค์
      referralReports: [
        { user: "User1", amount: 500 },
        { user: "User2", amount: 300 },
        { user: "User3", amount: 1000 },
        { user: "User1", amount: 500 },
        { user: "User2", amount: 300 },
        { user: "User3", amount: 1000 },
        { user: "User1", amount: 500 },
        { user: "User2", amount: 300 },
        { user: "User3", amount: 1000 },
        { user: "User1", amount: 500 },
        { user: "User2", amount: 300 },
        { user: "User3", amount: 1000 },
        
      ], // รายงานคนที่มาต่อเราและยอดเงินที่เติมมา
    };
  },
  methods: {
    copyReferralLink() {
      navigator.clipboard.writeText(this.referralLink);
      alert("Referral link copied!");
    },
  },
};
</script>

<style scoped>
/* การตกแต่งพื้นฐาน */
.account-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-top: 40px;
  margin-bottom: 0px;
  color: #333;
}

/* ส่วนที่ 1: รูปภาพ Avatar และ เลขกระเป๋า */
.account-info {
  display: flex;
  align-items: center;
}

.avatar {
  width: 100px;
  height: 100px; 
  margin-right: 10px; 
}

.wallet-info h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.wallet-info p {
  margin: 5px 0 0;
  font-size: 1rem;
  color: #555;
}

/* ส่วนที่ 2: ลิงค์อ้างอิงค์ */
.referral-section {
  margin: 20px 0;
  text-align: center;
}

.referral-link {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* ส่วนที่ 3: รายงาน */
.referral-report {
  margin-top: 20px;
}

.referral-report table {
  width: 100%;
  border-collapse: collapse;
}

.referral-report th, .referral-report td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
}

.referral-report th {
  background-color: #f4f4f4;
  color: #333;
}

.referral-report td {
  color: #555;
}

.referral-report tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
