<template>
    <div class="page-container">
      <div class="product-container" :style="productContainerStyle">
        <img :src="productImage" alt="Product Image" class="product-image" />
        <div class="product-details">
          <h2 class="product-name">{{ productName }}</h2>
          <p class="product-description">{{ productDescription }}</p>
          <p class="product-price">{{ productPrice }}</p>
          <button @click="buyProduct" class="buy-button">Buy Now</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import storeboxbg from '@/assets/storeboxbg.png'; // นำเข้าจาก src/assets
  import rareImage from '@/assets/RARE1_150x124_thumb.png'; // นำเข้าจาก src/assets
  
  export default {
    data() {
      return {
        productImage: rareImage, // ใช้รูปภาพนำเข้า
        productName: "Baby Boss", // ชื่อสินค้า
        productDescription: "This is a description of the product.", // รายละเอียดสินค้า
        productPrice: "0.1 TON", // ราคาสินค้า
        storeboxbg, // รูปภาพพื้นหลัง
      };
    },
    methods: {
      buyProduct() {
        alert("คุณได้ทำการสั่งซื้อสินค้าเรียบร้อยแล้ว!");
      },
    },
    computed: {
      productContainerStyle() {
        return {
          backgroundImage: `url(${this.storeboxbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding-top: 40%; /* ระยะห่างจากด้านบน */ 
  }
  
  .product-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;  
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .product-details {
    text-align: center;
  }
  
  .product-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .product-description {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #666;
  }
  
  .product-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .buy-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .buy-button:hover {
    background-color: #0056b3;
  }
  </style>
  