<template>
    <div class="nft-section">
      <h1 class="section-title">My NFT Collection</h1>
  
      <!-- Display loading message when loading -->
      <div v-if="loading" class="loading">Loading NFTs...</div>
  
      <!-- Display NFT grid once the data is loaded -->
      <div v-else class="nft-grid">
        <div v-for="(nft, index) in nfts" :key="index" :class="['nft-card', convertToLowerCase(nft.name)]">
          <div class="image-container">
            <img :src="nft.image" :alt="nft.name" class="nft-image" />
            <h2 class="nft-name">{{ nft.name }}</h2>
          </div> 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "MyNFT",
    data() {
      return {
        nfts: [],
        loading: false, // loading state
      };
    },
    methods: {
      async fetchNFTs() {
        this.loading = true; // start loading
        try {
          const response = await axios.get('https://tadmin.daily-shop.xyz/api/sprites');
          const sprites = response.data.sprites;
  
          // Map sprites to NFT data
          this.nfts = sprites.map(sprite => ({
            image: `/assets/${sprite.thumb}.png`,
            name: sprite.name,
            rarity: sprite.rarity, // add rarity property
          }));
  
          console.log(this.nfts); // Log the data to check the rarity values
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        } finally {
          this.loading = false; // stop loading
        }
      },
      convertToLowerCase(str){
        return 'nft-'+str.toLowerCase();
      }
      
    },
    created() {
      this.fetchNFTs();
    },
  };
  </script>
  
  <style scoped>
  .nft-section { 
    padding: 80px 20px; 
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .nft-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }
  
  .nft-card:hover {
    transform: translateY(-10px);
  }
  
  .image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
  }
  
  .nft-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 99%;
    height: 99%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
  
  .nft-name {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 0.3rem;
    z-index: 1;
  }
  
  /* Background colors for rarity types */
  .nft-common {
    background-color: #d3d3d3; /* light grey for COMMON */
     
  }
  
  .nft-uncommon {
    background-color: #76c7c0; /* teal for UNCOMMON */
     
  }
  
  .nft-rare {
    background-color: #b19cd9; /* light purple for RARE */
     
  }
  
  .nft-supperrare {
    background-color: #ffcc00; /* gold for SUPPERRARE */
     
  }
  
  .nft-legen {
    background-color: #ff6347; /* orange/red for LEGEN */
     
  }
  
  .loading {
    text-align: center;
    font-size: 1rem;
    color: #fff;
    margin: 20px 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .nft-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }
  
  @media (max-width: 600px) {
    .nft-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 5px;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  }
  </style>
  