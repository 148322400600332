// main.js
import { createApp } from "vue";
import App from "./App.vue";
import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";

import router from './router'; // Import router

// Create the app instance and use Vuestic and router
createApp(App)
  .use(createVuestic())
  .use(router) // Use the router
  .mount("#app");
