<!-- src/App.vue -->
<template>
  <div id="app" class="app-container" :style="{ backgroundImage: `url(${bgImage})` }">
    <!-- ปุ่มด้านบนซ้าย -->
    <TopLeftButton @left-clicked="handleLeftButton" />

    <!-- ปุ่มด้านบนขวา -->
    <TopRightButton @right-clicked="handleRightButton" />

    <router-view></router-view> <!-- เนื้อหาของแต่ละหน้า -->
    <FooterButtons /> <!-- ปุ่มด้านล่างที่จะปรากฏบนทุกหน้า -->
  </div>
</template>

<script>
import FooterButtons from './components/FooterButtons.vue';
import TopLeftButton from './components/TopLeftButton.vue';
import TopRightButton from './components/TopRightButton.vue';
import bgImage from '@/assets/bg.png';

export default {
  name: 'App',
  components: {
    FooterButtons,
    TopLeftButton,
    TopRightButton,
  },
  data() {
    return {
      bgImage,
    };
  },
  methods: {
    handleLeftButton() {
      // ตัวอย่างฟังก์ชัน: แสดง alert เมื่อคลิกปุ่มซ้าย
      alert('Left Button Clicked!');
      // คุณสามารถปรับปรุงฟังก์ชันนี้ให้ทำสิ่งอื่นๆ ตามที่ต้องการ
    },
    handleRightButton() {
      // ตัวอย่างฟังก์ชัน: แสดง alert เมื่อคลิกปุ่มขวา
      alert('Right Button Clicked!');
      // คุณสามารถปรับปรุงฟังก์ชันนี้ให้ทำสิ่งอื่นๆ ตามที่ต้องการ
    },
  },
};
</script>
  