<template>
  <div class="container"> 
    <!-- โลโก้ -->
    <img src="@/assets/logo3.png" alt="Logo" class="logo" />

    <!-- แสดงที่อยู่กระเป๋าที่เชื่อมต่อแล้ว -->
    <p v-if="address" class="text">Connected to: {{ formattedAddress }}</p>

    <!-- แสดงฟอร์มสำหรับกรอกจำนวนเงิน -->
    <form @submit.prevent="handleDeposit">
      <div class="form-group">
        <label for="amount">Enter TON amount:</label>
        <input 
          v-model.number="amount" 
          id="amount" 
          type="number" 
          step="0.01" 
          min="0.01" 
          placeholder="Enter amount in TON" 
          required
        />
      </div>
      <button 
        type="submit" 
        class="button primary-button" 
        :disabled="loading || !address"
      >
        Deposit TON
      </button>
    </form>
  
    <!-- แสดงข้อความโหลด -->
    <p v-if="loading" class="loading-message">Processing deposit...</p>

    <!-- แสดงข้อความสำเร็จ -->
    <p v-if="success" class="success-message">{{ success }}</p>

    <!-- แสดงข้อความผิดพลาด -->
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import { TonConnectUI } from '@tonconnect/ui';
import axios from 'axios';

export default {
  name: 'Deposit',
  
  data() {
    return {
      address: localStorage.getItem('walletAddress') || null, // ดึงที่อยู่กระเป๋ามาจาก localStorage
      amount: null,
      error: null,
      success: null,
      loading: false,
      tonConnectUI: null, // สำหรับจัดการการเชื่อมต่อ Ton
    };
  },

  mounted() {
    this.initializeTonConnect();
  },

  methods: {
    /**
     * Initialize TonConnectUI และตรวจสอบสถานะการเชื่อมต่อกระเป๋าเงิน
     */
    initializeTonConnect() {
      try {
        this.tonConnectUI = new TonConnectUI({
          manifestUrl: 'https://minigame.daily-shop.xyz/manifest.json',
        });

        // ตรวจสอบสถานะกระเป๋าเงินที่เชื่อมต่อ
        this.tonConnectUI.onStatusChange(this.handleStatusChange);
      } catch (error) {
        this.setError(`Failed to initialize TonConnect: ${error.message}`);
      }
    },

    /**
     * จัดการสถานะกระเป๋าเงิน
     */
    handleStatusChange(wallet) {
      if (wallet) {
        this.address = wallet.account.address;
        localStorage.setItem('walletAddress', this.address); // เก็บที่อยู่กระเป๋าใน localStorage
      } else {
        this.address = null;
        localStorage.removeItem('walletAddress');
      }
    },

    /**
     * จัดการการฝากเงิน
     */
    async handleDeposit() {
      if (!this.amount || this.amount <= 0) {
        this.setError('Please enter a valid amount.');
        return;
      }
  
      this.loading = true;
      try {
        // สร้าง transaction object 
        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            messages: [
                {
                    address: "UQD5w88YbfDyITUvaAcfNH3VPu6pNXfsQThtsWAEONSNR5wm",
                    amount: this.amount * 1e9, 
                }
            ]
        } 
        
        try {
            const result = await tonConnectUI.sendTransaction(transaction);

            // you can use signed boc to find the transaction 
            const someTxData = await myAppExplorerService.getTransaction(result.boc);
            this.setSuccess('Transaction was sent successfully' + someTxData);
             
        } catch (e) {
            console.error(e);
            this.setError('Transaction failed.' + e);
        }

        // ส่งธุรกรรมผ่าน TonConnectUI
        const result = await this.tonConnectUI.sendTransaction(transaction);
     
        
      } catch (error) {
        this.setError(`Deposit failed: ${error.message}`);
        console.error('Deposit Error:', error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Set the error message
     * @param {string} message - The error message to display
     */
    setError(message) {
      this.error = message;
      this.success = null; // เคลียร์ข้อความสำเร็จ
    },

    /**
     * Set the success message
     * @param {string} message - The success message to display
     */
    setSuccess(message) {
      this.success = message;
      this.error = null; // เคลียร์ข้อความผิดพลาด
    },
  },

  computed: {
    /**
     * Format the address to show the full wallet address
     */
    formattedAddress() {
      return this.address || '';  // แสดงที่อยู่กระเป๋าเต็ม
    },
  },
};
</script>
 
<style scoped>
:root {
  /* Theme Colors */
  --primary-color: #4CAF50;            /* เขียว */
  --primary-color-dark: #45a049;       /* เขียวเข้ม */
  --secondary-color: #ffffff;          /* ขาว */
  --overlay-color: rgba(0, 0, 0, 0.5); /* สีดำโปร่งแสง */
  
  /* Additional Colors */
  --success-color: #28a745;
  --error-color: #dc3545;
  --text-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column; /* จัดเรียงรายการแนวตั้ง */
  align-items: center;    /* จัดศูนย์รายการแนวนอน */
  justify-content: center;/* จัดศูนย์รายการแนวตั้ง */
  height: 100vh;          /* ความสูงเต็มหน้าจอ */
  text-align: center;     /* จัดตำแหน่งข้อความให้เป็นศูนย์ */

  /* Background Image with Overlay */
  background-image:  
    url('@/assets/bg.png'); /* ตรวจสอบให้แน่ใจว่าพาธถูกต้อง */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
}

/* สไตล์สำหรับโลโก้ */
.logo {
  width: 90%;           /* ปรับขนาดตามต้องการ */
  margin-bottom: 20px;  /* ช่องว่างด้านล่างโลโก้ */
}

/* สไตล์ปุ่มทั่วไป */
.button {
  padding: 12px 24px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color); /* ใช้สีธีม */
  color: var(--secondary-color);          /* ใช้สีข้อความธีม */
  transition: 
    background-color 0.3s ease, 
    transform 0.2s ease,
    box-shadow 0.3s ease;

  /* เพิ่ม box-shadow สำหรับความลึก */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: var(--primary-color-dark); /* เปลี่ยนสีเมื่อ hover */
  transform: translateY(-2px); /* ยกเลิกตำแหน่งเมื่อ hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.button:active {
  transform: translateY(0); /* รีเซ็ตตำแหน่งเมื่อคลิก */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* สไตล์เฉพาะสำหรับปุ่มหลัก (Connect Wallet) */
.primary-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.primary-button:hover {
  background-color: var(--primary-color-dark);
}

/* สไตล์เฉพาะสำหรับปุ่มรอง (Logout) */
.secondary-button {
  background-color: #f44336; /* แดง */
  color: var(--secondary-color);
}

.secondary-button:hover {
  background-color: #d32f2f; /* แดงเข้ม */
}

/* สไตล์ข้อความ */
.text {
  font-size: 18px;
  margin-top: 10px;
  color: var(--secondary-color); /* ให้แน่ใจว่าข้อความมองเห็นได้ */
  word-break: break-all;  /* จัดการข้อความที่ยาวเพื่อไม่ให้ล้นหน้าจอ */
}

/* สไตล์ข้อความสำเร็จ */
.success-message {
  color: var(--success-color);
  margin-top: 10px;
}

/* สไตล์ข้อความผิดพลาด */
.error-message {
  color: var(--error-color);
  margin-top: 10px;
}

/* สไตล์ข้อความโหลด */
.loading-message {
  color: var(--secondary-color);
  margin-top: 10px;
  font-style: italic;
}

/* สไตล์ฟอร์ม */
.form-group {
  margin-bottom: 20px;
  width: 100%;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
</style>
